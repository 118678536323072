import { useAuthStore } from "@/stores/auth";
import { useAppStateStore } from "@/stores/appState";
import { navigateTo } from 'nuxt/app';

export default defineNuxtRouteMiddleware((to, from) => {
  const authStore = useAuthStore();
  const appStateStore = useAppStateStore();
  console.log("start middleware", authStore.uid);

  // Check if the current route is not the login page and the user is not authenticated
  if (to.path !== '/login' && !authStore.uid) {
    // Redirect to the login page
    appStateStore.setShowMainModal(true)
    //return navigateTo('/register');
  }

  // If the user is authenticated or the current route is the login page, continue with the navigation
});